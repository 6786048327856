.itemsList {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
    margin-bottom: 25px;
}

.ItemPageHeader{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.wepItemDivHeader {
    display: flex;
    justify-content: space-evenly;
}

.defItemDivHeader{
    display: flex;
    justify-content: space-evenly;
}

.utilItemDivHeader {
    display: flex;
    justify-content: space-evenly;
}

.itemHeader {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.itemsDiv {
    display: flex;
}

.itemBox {
    display: flex;
    justify-content: center;
    border: 1px solid black;
    padding-top: 10px;
    padding-bottom: 2px;
    margin-right: 210px;
    margin-left: 210px;
}

.threebtns {
    display: flex;
    justify-content: center;
    margin-top: 15px
}

.addItemsToBag {
    display: flex;
    justify-content: center;
    margin-top:25px;
}

.delwepbtn {
    display: flex;
    margin-right: 15px;
}

.deldefbtn {
    display: flex;
    margin-right: 15px;
}

.ItemIndexHeaderDiv {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 25px;
}

.itemIndex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-right: 210px;
    margin-left: 210px;
    border: 1px solid black;
    padding-top: 15px;
}

.defenseIndexHeader {
    display: flex;
    justify-content: center;
}

.weaponIndexHeader {
    display: flex;
    justify-content: center;
}

.utilityIndexHeader {
    display: flex;
    justify-content: center;
}

.startAdventureBtn {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.ad-img-div{
    display: flex;
    justify-content: center;
    opacity: 1.0;
}

.ad-img {
    display: flex;
    justify-content: center;
    background: transparent;
    opacity: 1.0;
    border-radius: 25px;
    width: 200px;
    height: 200px;
    margin-bottom: 15px;
}

.ad-card {
    background: #828280;
    opacity: 0.9;
    width: 15rem;
    border-radius: 25px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    margin-top: 25px;
}

.adventures {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.ad-card-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: transparent;
}

.ad-card-title {
    display: flex;
    justify-content: center;
    padding-top: 15px;
    flex-direction: column;
}

.ad-card-name {
    display: flex;
    justify-content: center;
}

.ad-card-desc {
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.ad-btn-div {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.allItemsDiv {
    background: #828280;
    opacity: 0.9;
    background-clip: padding-box;
    margin-left: 50px;
    margin-right: 50px;
    border-radius: 25px;
    padding-bottom: 15px;
}