.createYourHeroDiv {
    background: #828280;
    opacity: 0.9;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 25px;
    background-clip: padding-box;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom:15px;
    border-radius: 25px
}

.create-title {
    opacity: 1.0;
    background: #828280;
}

.heroSubmitContainer {
    margin-top: 15px;
}