.dashboardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    text-align: center;
}

.torchTwo {
    display: flex;
    justify-content: left;
    flex-direction: row;
    width: 100px;
    height: 100px;
    padding-right:10px;
}

.torchOne {
    display:flex;
    justify-content: right;
    flex-direction: row;
    width: 100px;
    height: 100px;
    margin-right: 10px;
}

.dashCol {
    display: flex;
    flex-direction: column;
}

.dashHeaderDiv {
    background: #828280;
    opacity: 0.9;
    margin-top: 25px;
    padding-bottom: 15px;
    background-clip: padding-box;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 25px
}

.dashTitleAndWelcomeDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* padding-left: 25px; */
    margin-left: 25px;
}

.dashTitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.dashWelcomeDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashWelcome {
    display: flex;
    justify-content: center;
    flex-direction: column;
}


.rulesDiv {
    display: flex;
    justify-content: center;
}

.rulesHeaderDiv {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
}

.rulesHeader {
    display: flex;
    text-align: center;
    margin-bottom: 10px;
}


.rulesListDiv {
    display: flex;
    padding-right: 50px;
    padding-left: 50px;
}

.rules {
    display: flex;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 10px;
    border-bottom: 1px solid black;
}

.buttonsDash {
    display: flex;
    justify-content: center;
}

.createHeroDashBtn {
    display: flex;
    padding-right: 80px;
}

.viewHeroDashBtn {
    display: flex;
    padding-left: 80px;
}

.availableAdventuresDiv {
    background: #828280;
    opacity: 0.9;
    background-clip: padding-box;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 25px
}

.availableAdventuresHeaderDiv {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-right: 50px;
    padding-left: 50px;
    margin-top: 50px;
    padding-top: 15px;
}

.availableAdventuresHeader{
    display: flex;
    text-align: center;
    justify-content: center;
}

.availableAdventuresListDiv {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: row;
}

.availableAdventuresList {
    list-style-type: none;
    padding-right: 50px;
    padding-left: 50px;
}