html {
    background-image: url("../../img/stonewall.jpg")
}

body {
    background: transparent;
}

.heroes {
    background: transparent;
    margin-top: 25px;
}

.card-body {
    background: transparent;
}

.card {
    background: #828280;
    opacity: 0.9;
    width: 15rem;
    border-radius: 25px;
}

.card-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: transparent;
}

.hero-name {
    display: flex;
    justify-content: center;
    background: transparent;
}

.hero-desc {
    display: flex;
    justify-content: center;
    background: transparent;
}

.heroImgDiv {
    display: flex;
    justify-content: center;
    opacity: 1.0;
    background: transparent;
}

.heroesCards {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    background: transparent;
    flex-wrap: wrap;
}

.newHeroBtn {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    background: transparent;
}


.hero-img {
    display: flex;
    justify-content: center;
    background: transparent;
    opacity: 1.0;
    border-radius: 25px;
    width: 200px;
    height: 200px;
    margin-bottom: 15px;
}

.editHeroHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.heroForm {
    background: #828280;
    opacity: 0.9;
    margin-top: 25px;
    padding-left: 50px;
    padding-right: 50px;
    background-clip: padding-box;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 25px
}

.editHeroSubmitBtn {
    display: flex;
    justify-content: center;
    padding-bottom:15px;
}